import React from 'react';
import { Link } from 'gatsby';
import Page from '../components/Page';

function About() {
  return (
    <Page title="About" slug="/about">
      <p>
        If you regularly visit this <Link to="/">site</Link>, you’ve probably
        guessed from the posted by Michael Ly that my name is in fact Michael
        Ly. Born <a href="http://en.wikipedia.org/wiki/1988">1988 </a>and living
        in <a href="http://maps.google.co.uk/">London </a>(England). I dabble as
        some do in the mystic art of{' '}
        <a href="http://www.macromedia.com/software/flash/">Flash</a>{' '}
        (Macromedia Flash MX), but that’s really it about me. I like to drink
        milk.
      </p>

      <p>
        The name Rice Dog came about some time ago. I was pretty excited about
        the revelation I had just stumbled upon and on the way home from Chinese
        School I had decided to tell the whole family about this revelation in
        the car. I said to everyone with very little coolness and that innocent
        charm that I learnt how to say my english name in chinese. The words
        that made up my name happen to be Rice Dog and everyone laughed and I
        was humiliated but it sprouted a very cool name, Rice Dog and that is
        how the name came to being.
      </p>
    </Page>
  );
}

export default About;
